<template>
  <div class="h-100" style="overflow-y: auto">
    <div style="overflow-y: auto; background-color: #101010;" class="h-100"  infinite-wrapper>
      <!-- Mass Edit / My Listings bar -->
      <div style="background-color: #101010;" class="my-3">
        <div class="container">
          <div class="mt-3 d-flex">
            <div class="d-flex" style="max-width: 200px; width:200px; height: 40px">
              <button
                class="btn btn-block text-nowrap"
                :class="!massEdit ? 'btn-deselected' : 'btn-secondary'"
                @click="setMassEdit(true)"
              >Mass Edit Tool</button>

              <button
                class="btn btn-block text-nowrap"
                :class="massEdit ? 'btn-deselected' : 'btn-secondary'"
                @click="setMassEdit(false)"
                style="margin: 0px 0px 0px 10px !important;"
              >My Listings</button>
            </div>

            <div class="ml-auto" :class="!massEdit ? 'invisible' : ''">
              <div class="d-flex">
                <span class="rubik mr-3 h6 my-auto text-white" :class="checkPricesModifiedMassEdit ? '' : 'invisible'">Unsaved changes!</span>
                <button
                  v-if="saveChangesLoading"
                  type="button"
                  class="btn btn-secondary text-nowrap"
                  style="font-weight: bold; width: 150px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  
                  >
                    <div class="spinner-border text-white spinner-border-sm" style="font-size: 12px; margin-left: 3px;" role="status">
                    </div>
                  </button>
                <button
                  v-else-if="checkPricesModifiedMassEdit"
                  type="button"
                  class="btn btn-secondary text-nowrap"
                  style="font-weight: bold; width: 150px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  @click="saveChanges"
                  >Save Changes</button>
                
                <button
                  type="button"
                  v-if="checkPricesModifiedMassEdit"
                  class="btn btn-danger text-nowrap mx-3"
                  style="font-weight: bold; width: 140px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  @click="resetChangesMassEdit"
                  >Reset Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TODO: Move these templates into their own components. -->
      <!-- not mass edit -->
      <template v-if="!massEdit">
        <div style="background-color: #1D2021;">
          <div class="container">
            <div class="row py-3">
              <div class='d-flex ml-2'>
                <div class="mx-2 game-icon-selector" :class="game == 'TF2' || game == 'All'  ? 'active' : ''" @click="selectGame('TF2')"><img src="@/assets/tf2logosm.jpg"></div>
                <div class="mx-2 game-icon-selector" :class="game == 'CS:GO' || game == 'All'  ? 'active' : ''" @click="selectGame('CS:GO')"><img src="@/assets/csgologosm.jpg"></div>
                <div class="mx-2 game-icon-selector" :class="game == 'Rust' || game == 'All'  ? 'active' : ''" @click="selectGame('Rust')"><img src="@/assets/rustlogosm.jpg"></div>
                <div class="mx-2 game-icon-selector" :class="game == 'Dota 2' || game == 'All'  ? 'active' : ''" @click="selectGame('Dota 2')"><img src="@/assets/dota2logosm.jpg"></div>
              </div>
              <div class="mr-5 ">
                  <div class="dropdown text-white my-auto" style="padding-top: 6px;">
                    <a
                      class="rubik hover-pointer"
                      type="a"
                      id="dropdownMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="text-white font-weight-light mr-2 ml-2">Sort By:</span>
                      <span class="text-white font-weight-bold">{{ sortOrderDisplay }} </span>
                      <font-awesome-icon icon="caret-down" />
                    </a>
                    <div class="dropdown-menu simple-dropdown-container" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('nameAsc')">Name (Ascending)</a>
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('nameDesc')">Name (Descending)</a>
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('priceLow')">Price (Low to High ↑)</a>
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('priceHigh')">Price (High to Low ↓)</a>
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('rarity')">Rarity</a>
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('floatLow')">Float (Low to High ↑)</a>
                      <a class="dropdown-item simple-dropdown-item" href="#0" @click="setSortOrder('floatHigh')">Float (High to Low ↓)</a>
                    </div>
                  </div>
              </div>
              <div>
                <input
                  class="form-control text-white search-bar"
                  style="width: 250px;"
                  type="text"
                  name="Search Query"
                  id="searchQuerySimple"
                  placeholder="Search My Listings"
                  v-model="searchQuerySimple"
                  @focus="clearPlaceholder"
                  @blur="setListingsPlaceholder"
                />
              </div>
              <div class="my-auto mx-3" v-if="unsavedChanges">
                <font-awesome-icon v-tooltip="'Unsaved Changes'" icon="exclamation-triangle" style="color: orange;"/>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 mx-auto" :style="{'maxWidth': ((this.itemsPerRow - 1)*32) + (this.itemsPerRow)*263 + 'px'}">
            <div class="d-flex justify-content-start" v-for="(row, i) in filteredItemsSimple" v-bind:key="i">
              <div v-for="item in row" v-bind:key="'simple_'+item.id">
                <Item class="m-2" :item="item" :listingsPage="true" @listingChanged="refreshListingDisplay" @listingRemoved="removeItem" @priceChanged="handlePriceChange(1, $event)" @priceUnchanged="handlePriceChange(-1, $event)" @itemDestroyed="pricesChanged.splice(pricesChanged.indexOf($event), 1)" :ref="'item-'+item.id"></Item>
              </div>
            </div>
            <infinite-loading v-if="!errorLoading" @infinite="infiniteHandler" ref="InfiniteLoading" force-use-infinite-wrapper></infinite-loading>
            <div v-if="errorLoading">Error loading</div>
        </div>
      </template>

      <!--  mass edit -->
      <template v-if="massEdit">
        <div style="background-color: #1D2021; padding: 10px 0px 10px 0px;">
          <div class="container">
            <div class="d-flex">
              <div class="text-white my-auto mr-5" style="font-weight: 500;">
                <template v-if="allItemsSelected" ><span style="color: #76C94D">All</span> Item(s) Selected</template>
                <template v-else ><span style="color: #76C94D">{{selectedItems.length}}</span> Item(s) Selected</template>
              </div>

              <div class="mx-2">
                <button
                  type="button"
                  class="btn btn-secondary text-nowrap"
                  style="font-weight: bold; width: 150px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  @click="matchLowest"
                >Match Lowest</button>
              </div>
              <div class="mx-2">
                <button
                  type="button"
                  class="btn btn-secondary text-nowrap"
                  style="font-weight: bold; width: 150px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  @click="decreaseOnePercent"
                >Decrease by 1%</button>
              </div>
              <div class="mx-2">
                <button
                  type="button"
                  class="btn btn-secondary text-nowrap"
                  style="font-weight: bold; width: 150px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  @click="increaseOnePercent"
                >Increase by 1%</button>
              </div>
              <div class="mx-2">

                <div class="d-flex">
                  <div class="input-group-prepend">
                    <span class="input-group-text brl" id="basic-addon1" style="border-top-right-radius:0;border-bottom-right-radius:0">$</span>
                  </div>
                  <currency-input
                    placeholder="0.00"
                    :value="specificPrice"
                    @change="specificPrice = $event"
                    style="background-color: white; color: black; border-radius: 0; width: 100px"
                    :options="{
                      currency: 'USD',
                      currencyDisplay: 'hidden',
                      hideCurrencySymbolOnFocus: false,
                      hideGroupingSeparatorOnFocus: false,
                      hideNegligibleDecimalDigitsOnFocus: false,
                      useGrouping: true,
                      valueRange: { min: 0, max: 100000 },
                    }"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text" style="border-radius: 0px 15px 15px 0px; border: 0; background-color: #ED6B33;">
                      <a class="hover-pointer" @click="setPrice" >
                        <span>Set Price</span>
                      </a>
                    </div>
                  </div>
              </div>
              </div>
              <div class="mx-2">
                <button
                  type="button"
                  class="btn btn-secondary text-nowrap"
                  style="font-weight: bold; width: 150px; height: 40px; border-radius: 10px; font-size: 14px; text-align: center; padding: 0px;"
                  @click="unlistSelected"
                >Un-list</button>
              </div>
            </div>
          </div>
        </div>
        <hr class="solid" style="border: 1px solid black; margin: 0px; padding: 0px;" />

        <div style="background-color: #1D2021;">
          <div class="py-3 d-flex container">

            <div class="my-auto form-check form-check-inline" style="margin-right: 50px;">
              <label class="checkmark-container" style="width:80px; font-size: 14px; height: 20px; margin-right: 16px; margin-bottom: 16px;">
                <input type="checkbox" v-model="allItemsSelected" @click="selectAllItems"/>
                <span class="checkmark"></span>
              </label>
              <a
                href="#"
                class="text-white"
                style="margin-left: 5px;white-space: pre;"
                @click="selectAllItems"
              >Select All</a>
            </div>

            <div class="my-auto" style="width: 300px; margin-right: 70px;">
              <input
                class="form-control text-white search-bar"
                type="text"
                name="Search Query"
                id="searchQueryMassEdit"
                placeholder="Search By Keyword:"
                v-on:keydown="allItemsSelected = false"
                v-model="searchQueryMassEdit"
                @focus="clearPlaceholder"
                @blur="setKeywordPlaceholder"
              />
            </div>

            <div class="text-white my-auto" style="margin-right: 40px;">
              <a id href="#0" style="text-decoration: none;" @click="toggleSortOrderMassEdit('currentPriceDesc')">
                <span class="text-white font-weight-light mr-2">Current Price</span>
                <font-awesome-icon icon="caret-up" v-if="sortOrderMassEdit == 'currentPriceAsc'" style="color:#ED6B33;"/>
                <font-awesome-icon icon="caret-down" v-if="sortOrderMassEdit == 'currentPriceDesc'" style="color:#ED6B33;"/>
                <font-awesome-icon icon="caret-down" v-if="sortOrderMassEdit != 'currentPriceAsc' && sortOrderMassEdit != 'currentPriceDesc'"/>
              </a>
            </div>

            <div class="text-white my-auto" style="margin-right: 10px;">
              <a id href="#0" style="text-decoration: none;" @click="toggleSortOrderMassEdit('lowestPriceDesc')">
                <span class="text-white font-weight-light mr-2">Lowest Price</span>
                <font-awesome-icon icon="caret-up" v-if="sortOrderMassEdit == 'lowestPriceAsc'" style="color:#ED6B33;"/>
                <font-awesome-icon icon="caret-down" v-if="sortOrderMassEdit == 'lowestPriceDesc'" style="color:#ED6B33;;"/>
                <font-awesome-icon icon="caret-down" v-if="sortOrderMassEdit != 'lowestPriceAsc' && sortOrderMassEdit != 'lowestPriceDesc'"/>
              </a>
            </div>

            <div class="my-auto form-inline" style="margin-right: 14px;">
              <label class="text-white" style="margin-right: 10px;">Price:</label>
              <input class="input-range" type="text" placeholder="Min" v-model="minPriceRange"/>
              <span class="text-white" style="padding: 0px 10px;">-</span>
              <input class="input-range" type="text" placeholder="Max" v-model="maxPriceRange"/>
            </div>

            <div class="my-auto">
              <div class='d-flex'>
                <div class="mx-2 game-icon-selector" :class="game == 'TF2' || game == 'All'  ? 'active' : ''" @click="selectGame('TF2')"><img src="@/assets/tf2logosm.jpg"></div>
                <div class="mx-2 game-icon-selector" :class="game == 'CS:GO' || game == 'All'  ? 'active' : ''" @click="selectGame('CS:GO')"><img src="@/assets/csgologosm.jpg"></div>
                <div class="mx-2 game-icon-selector" :class="game == 'Rust' || game == 'All'  ? 'active' : ''" @click="selectGame('Rust')"><img src="@/assets/rustlogosm.jpg"></div>
                <div class="mx-2 game-icon-selector" :class="game == 'Dota 2' || game == 'All'  ? 'active' : ''" @click="selectGame('Dota 2')"><img src="@/assets/dota2logosm.jpg"></div>
              </div>

            </div>
          </div>
        </div>

        <div class="container mt-2">
          <div v-for="item in filteredItemsMassEdit" v-bind:key="'mass_'+item.market_hash_name+'_'+item.id">
            <div class="row">
              <div class="col">
                <label class="checkmark-container" style="width:80px; font-size: 14px; height: 10px;">
                  <input type="checkbox" @click="selectItem(item)" :checked="item.selected"/>
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="col-4">
                <div class="d-flex">
                  <div class="item-tooltip-container"><span class="item-name">{{item.name}}</span>
                    <Item class="item-tooltip" :item="item" :ref="'item-'+item.id"></Item>
                  </div>
                  <span v-if="item.wear" class="text-white ml-1"> ({{item.wear}}) </span>

                  <font-awesome-icon v-if="item.stickers && item.stickers.length > 0" icon="exclamation-triangle" color="#ED6B33" style="cursor: pointer; margin: 3px 0px 0px 5px;" v-tooltip="getItemContainsStickersText(item.appId)"/>
                </div>
              </div>
              <div class="col">
                <p>
                  <span style="font-weight: 400;" :style="{'color': item.listPrice != item.previousListPrice ? '#ED6B33' : '#76C94D'}">{{formatPrice(item.listPrice)}}</span>
                </p>
              </div>
              <div class="col">
                <p>
                  <span
                    class="text-white"
                  >{{formatPrice(lowestPrices[item.market_hash_name])}}</span>
                </p>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
          <infinite-loading v-if="!errorLoading" @infinite="infiniteHandler" ref="InfiniteLoading" force-use-infinite-wrapper></infinite-loading>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import parseItems from "../utils/parseItems";
import api from "@/api.js";

const stringSimilarity  = require("string-similarity");
const numeral           = require('numeral');
const validInputs       = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."]

export default {
  data() {
    return {
      searchQuerySimple: "",
      searchQueryMassEdit: "",
      sortOrder: "",
      sortOrderDisplay: "None",
      sortOrderMassEdit: "",
      sortOrderMassEditDisplay: "None",
      game: "All",
      massEdit: false,
      minPriceRange: "",
      maxPriceRange: "",
      specificPrice: "",
      pricesChanged: [],
      errorLoading: false,
      allItemsSelected: false,
      isMoreItems: true,
      lowestPrices: {},
      windowWidth: 0,
      saveChangesLoading: false,
    };
  },
  computed: {
    unsavedChanges(){
      return this.pricesChanged.length !== 0;
    },
    itemsPerRow(){
      return Math.max(Math.min(Math.floor(this.windowWidth/300), this.allItems.length), 4);
    },
    checkPricesModifiedMassEdit(){
      for (let item of this.selectedItems){
        if (item.previousListPrice && item.listPrice != item.previousListPrice){
          return true;
        }
      }
      return false;
    },
    allItems() {
      let items = this.$store.getters["items/my_listings"];
      let seenCache = {};
      items = items.filter(item => {
        const seen = seenCache[item.id];
        seenCache[item.id] = true;
        return !seen;
      })

      return items;
    },
    selectedItems() {
      return this.$store.getters["items/selectedMyListings"];
    },
    filteredItemsSimple() {
      let filteredAllItems = JSON.parse(JSON.stringify(this.allItems));
      let self = this;
      // Searching
      if (this.searchQuerySimple && this.searchQuerySimple.length > 1) {
          let search = this.searchQuerySimple.toLowerCase()
          // Split item name into words array and check if searchQuerySimple is similar to any of the words in array
          filteredAllItems = filteredAllItems.filter(function(item){
              let words = item.market_hash_name.split(' ');
              // Removing vertical bar from array
              let bar = words.indexOf("|") 
              words.splice(bar, 1);

              for(let word of words){
              word = word.toLowerCase()
              if(stringSimilarity.compareTwoStrings(search, word) >= 0.4){
                  return item;
              }
              }
          })
      }
      // Sorting
      if (this.sortOrder) {
          let order = this.sortOrder;
          self.compareItemsUsingOrder(filteredAllItems, order)
      } 

      // Game filter
      switch(this.game) {
        case 'CS:GO':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 730);
          break;
        case 'TF2':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 440);
          break;
        case 'Dota 2':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 570);
          break;
        case 'Rust':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 252490);
          break;
      }

      // Update list of items with changed prices to hide icon
      // Since the Item components get removed/redrawn, we need to do this unlike in MassEdit mode
      // console.log(this.pricesChanged)
      this.$set(this.$data, "pricesChanged", this.pricesChanged.filter(x => filteredAllItems.findIndex(i => x.id == i.id) == -1))
      // console.log(this.pricesChanged)

      const seenCommodities = [];
      const uniqueItems = [];
      for (const item of filteredAllItems) {
        if (item.type == 'COMMODITY') {
          console.log("Got name" + item.market_hash_name);
          if (seenCommodities.indexOf(item.market_hash_name) > -1) {
            continue;
          }

          seenCommodities.push(item.market_hash_name);
        }
        uniqueItems.push(item);
      }

      // Do the 'binning' into groups of itemsPerRow
      return uniqueItems.reduce((col, item, i) => {
          if (i % this.itemsPerRow === 0) col.push([]); // Add a new row

          col[col.length - 1].push(item); // push item to the current row
          return col;
      }, []);
    },
    filteredItemsMassEdit() {
      let filteredAllItems = JSON.parse(JSON.stringify(this.allItems));
      let self = this;
      let seenCache = {};

      // Game filter
      switch(this.game) {
        case 'CS:GO':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 730);
          break;
        case 'TF2':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 440);
          break;
        case 'Dota 2':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 570);
          break;
        case 'Rust':
          filteredAllItems = filteredAllItems.filter(x => x.appId === 252490);
          break;
      }

      // Searching
      if (this.searchQueryMassEdit && this.searchQueryMassEdit.length > 2) {
        let search = this.searchQueryMassEdit.toLowerCase().replace(/|/gmi,'');
        // Split item name into words array and check if searchQueryMassEdit is similar to  any of the words in array
        filteredAllItems = filteredAllItems.filter(function(item) {
          if (item.market_hash_name.toLowerCase().replace(/|/gmi, '').indexOf(search) > -1) return true;

          return false;
          // Below is a fussy search we tried, that was a bit too aggressive, so above is a much more strict version
          // let words = item.name.split(" ");
          // // Removing vertical bar from array
          // let bar = words.indexOf("|");
          // words.splice(bar, 1);

          // for (let word of words) {
          //   word = word.toLowerCase();
          //   if (stringSimilarity.compareTwoStrings(search, word) >= 0.8) {
          //     return item;
          //   }
          // }
        });
      }
      // Sorting
      if (this.sortOrderMassEdit) {
        this.compareItemsUsingOrder(filteredAllItems, this.sortOrderMassEdit);
      }
      // Range
      if (this.minPriceRange){
        filteredAllItems = filteredAllItems.filter(item => item.listPrice >= this.minPriceRange)
      }
      if (this.maxPriceRange){
        filteredAllItems = filteredAllItems.filter(item => item.listPrice <= this.maxPriceRange)
      }

      filteredAllItems = filteredAllItems.filter(item => {
        const seen = seenCache[item.id];
        seenCache[item.id] = true;
        return !seen;
      })

      return filteredAllItems;
    },
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted() {
    console.log(this.$route.name);
    this.updateListings();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.onResize();
  },
  methods: {
    async unlistSelected(){
      if (this.selectedItems.length === 0){
        Swal.fire("Error", "No items selected!", "error")
        return;
      }
      
      let totalItems = this.selectedItems.length;
      let currentItems = totalItems;

      let confirm = await Swal.fire({ title: `Unlist ${totalItems} items?`, html: `Are you sure you wish to unlist ${totalItems} items?`, icon: "info",  showCancelButton: true,});
      console.log(confirm);

      if (confirm.dismiss) return;

      Swal.fire({
        title: `Un-listing selected items...`,
        html: `<span class="swal2-status">Remaining: ${currentItems}/${totalItems}</span>`,
        //showCancelButton: false,
        //showConfirmButton: false,
        allowOutsideClick: false,
        onBeforeOpen: async () => {
          for(let item of this.selectedItems){
            try {
              Swal.showLoading();

              this.$refs[`item-${item.id}`][0].removeFromListings();
              await this.$store.commit('items/removeListing', item.id);
              currentItems--;
              $('.swal2-status').html(`<span class="swal2-status">Remaining: ${currentItems}/${totalItems}</span>`);
            } catch (error) {
              console.error(error);
            }
          }
          Swal.close();
        },
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    getItemContainsStickersText(appid){
      switch(appid) {
        case 730:
          return 'May contain stickers';
        case 440:
          return 'May contain Strange Parts or Halloween Spells'
        case 570:
          return 'May contain Gems'
      }
    },
    selectGame(game) {
      if (this.game == game) return this.game = "All";
      this.game = game;
      this.updateListings();
    },
    resetChangesMassEdit(){
      for (let item of this.selectedItems) {
        item.listPrice = item.previousListPrice;
      }

      for (let item of this.filteredItemsMassEdit) {
        item.listPrice = item.previousListPrice;
      }
    },
    resetChangesSimple() {
      for (let ref of Object.values(this.$refs)) {
        try{
          ref[0].setPrevious();
        } catch (e) {
          console.log(e);
        }
      }
    },
    handlePriceChange(val, id){      
      if (val == 1) { // Price changed
        if (this.pricesChanged.indexOf(id) == -1){ // Add to list of items with prices changed
          this.pricesChanged.push(id);
        }
      }
      else if (val == -1 && this.pricesChanged.indexOf(id) >= 0) {
        this.pricesChanged.splice(this.pricesChanged.indexOf(id), 1)
        //this.$set(this.$data, "pricesChanged", this.pricesChanged.filter(x => x.id == id));
      }
    },
    async removeItem(id){
      await this.$store.commit('items/removeListing', id);
      console.log(`Asked to remove ${id} from my_listings store`);
      this.$forceUpdate();
    },
    refreshListingDisplay(id){
      // Updating the item in the Mass Edit Section
      for (let item of this.allItems){
        if (item.id == id){
          this.$store.commit("items/setListing", item) // This is updating the item
          item.previousListPrice = item.listPrice;
          item.lowestPrice = this.lowestPrices[item.market_hash_name];
        }
      }
      // Removing item from list of unsaved changes
      //this.$set(this.$data, "pricesChanged", this.pricesChanged.filter(x => x.id == id));
    },
    setPrice() {
      // TODO; Validate
      console.log("Wants to set price");
      if (isNaN(parseFloat(this.specificPrice))) Swal.fire({ title: "Invalid price", icon: "error" });

      if (this.specificPrice < 0.05) return Swal.fire({ title: "Price too low", text: "Try setting a price higher than 0.05", icon: "error"})

      // they want to set the price of all selected items
      for (let item of this.selectedItems) {
        item.listPrice = this.specificPrice
      }
    },
    getValidString(str){
      str = str + ''
      let dotCount = 0;

      let validString = ""
      for (let i in str){
        let char = str[i];
        if (char == "."){
          dotCount ++;
        }
        if(validInputs.indexOf(char) > -1 && dotCount <= 1){
          validString += char
        }
      }
      return validString
    },
    async updateListing(item){
      console.log("Updated item price for item " + item.name)
      let price = parseFloat(numeral(item.listPrice).format('0.[00]')); // Backwards but hey it works
      let params = {
        resourceId: item.id,
        changes: [{          
          key: "listPrice",
          value: price,
          oldValue: item.previousListPrice
        }]
      }
      // Updating previousListPrice to the current value
      item.previousListPrice = price;
      item = await this.$store.dispatch('items/updateListing', params)
      console.log(item)
    },
    async saveChanges(){
      if(this.selectedItems.length == 0){
        return alert("Nothing selected!")
      }

      var sweet_loader = '<div class="sweet_loader"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div>';
      
      let promises = [];
      for(let item of this.selectedItems){
        promises.push(this.updateListing(item))
      }

      try {
        this.saveChangesLoading = true;
        await Promise.all(promises);
        this.saveChangesLoading = false;
        this.specificPrice = "";
        Swal.fire({ icon: "success", title: "Changes are live!", text: "Good luck on sales!" });
      } catch (e) {
        Swal.fire({ icon: "error", title: "Unable to save all prices", text: "One or more listings failed to update."});
        console.error(e.stack);
        console.error(e);
        debugger;
      }

      // TODO: Do we need to re-query db for this?
      await this.updateListings()

    },
    matchLowest(){
      for(let item of this.selectedItems){
        item.listPrice = this.lowestPrices[item.market_hash_name];
      }
    },
    decreaseOnePercent(){
      let isItemsTooLow = this.selectedItems.filter(item => item.listPrice <= 0.05);
      if (isItemsTooLow.length > 0) {
        return alert("Cannot reduce price below 5 cents. Sorry!");
      }

      for(let item of this.selectedItems) {
        if (item.listPrice <= 1){
          item.listPrice -= 0.01;
        } else if (item.listPrice*0.99 > 0.10){
          item.listPrice = parseFloat(numeral(item.listPrice*0.99).format('0.[000]'));
        }

        this.allItems.find(x => x.id == item.id).listPrice = item.listPrice;
      }
    },
    increaseOnePercent(){
      for(let item of this.selectedItems){

        if(item.listPrice <= 1){
          item.listPrice += 0.01
        } 
        else{
          item.listPrice = parseFloat(numeral(item.listPrice*1.01).format('0.[00]'));
        }

        if (item.listPrice > 10000) item.listPrice = 10000;
        this.allItems.find(x => x.id == item.id).listPrice = item.listPrice;
      }
    },
    async selectAllItems(){
      this.allItemsSelected = !this.allItemsSelected;

      if (this.allItemsSelected) {
        while (this.isMoreItems) {
          Swal.showLoading();
          console.log("Loading more!");
          this.isMoreItems = await this.$store.dispatch("items/loadMoreMyListings");
          // if (this.allItemsSelected) for (let item of this.filteredItemsMassEdit) item.selected = true; 
        }
        Swal.close();
      }

      if (this.allItemsSelected) {
        for (let item of this.filteredItemsMassEdit) {
          this.allItems.find(x => x.id == item.id).selected = true; // Set to false if theyre unticking
        }
      } else {
        for (let item of this.allItems) {
         item.selected = false; // Set to false if theyre unticking
        }
      }
    },
    selectItem(item){
      // Index of selectedItem has to be the same as filteredItems
      let referencedItem = this.allItems.find(x => x.id == item.id)
      referencedItem.selected = !item.selected
      if (referencedItem.selected == false) {
        this.allItemsSelected = false;
      }
    },
    async updateListings(){
      console.log("Updated listings")
      await this.$store.dispatch("items/updateMyListings"); // TODO: Change this if you're excluding user's items
      await this.refreshListings()
    },
    async infiniteHandler($state) {
      console.log("inifinite handler hit");
      // Decide the whole complete thing.
      this.isMoreItems = await this.$store.dispatch("items/loadMoreMyListings");

      this.updateLowestPrices();

      // If all items is seleted, mark the new items as selected.
      if (this.allItemsSelected){ 
        for (let item of this.allItems) {
          item.selected = true;
        }
      }
      console.log(`Is more to load: ${this.isMoreItems}`)
      if (this.isMoreItems)
          $state.loaded();
      else
          $state.complete();

    },
    
    async refreshListings(){
      // clear the old my_listings and load a fresh?
      console.log("Attempting refresh")
      await this.$store.dispatch('items/reset');
      await this.$store.dispatch('items/updateMyListings');

      if(this.$refs.InfiniteLoading){
        this.$refs.InfiniteLoading.stateChanger.reset(); 
      }
      
      this.updateLowestPrices();
      
      console.log("Refresh complete");
    },
    async updateLowestPrices() {
      for (let item of this.allItems) {
        let uniqueItemHash = `${item.market_hash_name}`; // TODO: Move into a function?

        if (this.lowestPrices[uniqueItemHash]) {
          item.lowestPrice = this.lowestPrices[uniqueItemHash];
          continue;
        }

        let cheapestListedItem = await api.getItems({
          "state": "LISTED",
          "location": "SITE",
          market_hash_name: item.market_hash_name,
          sortBy: "priceasc",
          maxItems: 1,
        })

        console.log(`Got lowest price for${uniqueItemHash} response`);
        this.lowestPrices[uniqueItemHash] = cheapestListedItem.data[0].listPrice;
        // TODO: Move to vuex.
        item.lowestPrice = this.lowestPrices[uniqueItemHash];
      this.$forceUpdate();
      }
      this.$forceUpdate();
    },
    formatPrice(price){
      if (isNaN(price) || Math.POSITIVE_INFINITY == price) return "-";
      
      return numeral(price).format('$0.[00]')
    },
    async setMassEdit(truth) {
      // Updating listings when switching modes
      this.refreshListings();
      this.massEdit = truth;
      // Resetting search/sort
      this.sortOrder = "";
      this.sortOrderDisplay = "None";
      this.sortOrderMassEdit = "";
      this.sortOrderMassEdit = "None";
      this.searchQuerySimple = "";
      this.searchQueryMassEdit = "";
    },
    calculateTextColor(item) { // TODO: Change to when price is changed or use inline?
      let avg = 1;
      let color = "#ED6B33";
      if (item.listPrice > avg) {
        color = "#76C94D";
      }
      return color;
    },
    clearPlaceholder(e) {
      e.target.placeholder = "";
    },
    setKeywordPlaceholder(e){
      e.target.placeholder = "Search By Keyword:"
    },
    setListingsPlaceholder(e){
      e.target.placeholder = "Search My Listings"
    },
    setSortOrder(sortOrder) {
      this.sortOrder = sortOrder;
      switch (sortOrder) {
        case "nameAsc":
          this.sortOrderDisplay = "Name (Ascending)";
          break;
        case "nameDesc":
          this.sortOrderDisplay = "Name (Descending)";
          break;
        case "priceLow":
          this.sortOrderDisplay = "Price (Low to High ↑)";
          break;
        case "priceHigh":
          this.sortOrderDisplay = "Price (High to Low ↓)";
          break;
        case "rarity":
          this.sortOrderDisplay = "Rarity";
          break;
        case "floatLow":
          this.sortOrderDisplay = "Float (Low to High ↑)";
          break;
        case "floatHigh":
          this.sortOrderDisplay = "Float (High to Low ↓)";
          break;
      }
    },
    toggleSortOrderMassEdit(sortOrder){
      if(this.sortOrderMassEdit == sortOrder){
        sortOrder.includes('Desc') ? sortOrder = sortOrder.replace('Desc', 'Asc') : sortOrder = sortOrder.replace('Asc', 'Desc')
      }
      this.sortOrderMassEdit = sortOrder;
    },
    convertRarity(rarity) {
      switch (rarity) {
        case "Consumer Grade":
          return 1;
        case "Industrial Grade":
          return 2;
        case "Mil-spec":
          return 3;
        case "Restricted":
          return 4;
        case "Classified ":
          return 5;
        case "Covert ":
          return 6;
        case "Exceedingly Rare ★":
          return 7;
        case "Contraband ":
          return 8;
        default:
          return 0;
      }
    },
    compareItemsUsingOrder(arr, order) {
      let self = this;
      return arr.sort(function(a, b) {
        if (order === "nameAsc") {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        } else if (order === "nameDesc") {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        } else if (order === "priceLow") {
          if (a.listPrice < b.listPrice) {
            return -1;
          }
          if (a.listPrice > b.listPrice) {
            return 1;
          }
          return 0;
        } else if (order === "priceHigh") {
          if (a.listPrice < b.listPrice) {
            return 1;
          }
          if (a.listPrice > b.listPrice) {
            return -1;
          }
          return 0;
        } else if (order === "rarity") {
          let rarA = self.convertRarity(a.rarity.name);
          let rarB = self.convertRarity(b.rarity.name);
          if (rarA < rarB) {
            return 1;
          }
          if (rarA > rarB) {
            return -1;
          }
          return 0;
        } else if (order === "floatLow") {
          // TODO: Make sure non-CSGO allItems aren't scuffing everything
          if (a.float < b.float) {
            return -1;
          }
          if (a.float > b.float) {
            return 1;
          }
          return 0;
        } else if (order === "floatHigh") {
          // TODO: Make sure non-CSGO allItems aren't scuffing everything
          if (a.float < b.float) {
            return 1;
          }
          if (a.float > b.float) {
            return -1;
          }
          return 0;
        } else if (order === "currentPriceDesc") {
          // TODO: Make sure non-CSGO allItems aren't scuffing everything
          if (a.listPrice < b.listPrice) {
            return 1;
          }
          if (a.listPrice > b.listPrice) {
            return -1;
          }
          return 0;
        }
        else if (order === "currentPriceAsc") {
          // TODO: Make sure non-CSGO allItems aren't scuffing everything
          if (a.listPrice > b.listPrice) {
            return 1;
          }
          if (a.listPrice < b.listPrice) {
            return -1;
          }
          return 0;
        } else if (order === "lowestPriceDesc") {
          // TODO: Make sure non-CSGO allItems aren't scuffing everything
          if (a.lowestPrice > b.lowestPrice) {
            return 1;
          }
          if (a.lowestPrice < b.lowestPrice) {
            return -1;
          }
          return 0;
        } else if (order === "lowestPriceAsc") {
          // TODO: Make sure non-CSGO allItems aren't scuffing everything
          if (a.lowestPrice < b.lowestPrice) {
            return 1;
          }
          if (a.lowestPrice > b.lowestPrice) {
            return -1;
          }
          return 0;
        }
      });
    }
  },
  watch:{
    minPriceRange(){
      this.minPriceRange = this.getValidString(this.minPriceRange)
    },
    maxPriceRange(){
      this.maxPriceRange = this.getValidString(this.maxPriceRange)
    }
  },
  async beforeRouteLeave(to, from, next){
    // If there are unsaved changes, bring up prompt
    if (this.checkPricesModifiedMassEdit) {
      let result = await Swal.fire({
        title: 'Wait!',
        text: "You are about to lose all your changes without saving!",
        icon: 'warning',
        showCancelButton: true, 
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: "It's OK!",
        confirmButtonText: 'Save my stuff!',
        heightAuto: false,
      });

      if (result.value) {
        next();
      }
    }
    
    next()
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/brand.scss";

input::placeholder {
  color: #d3d3d3;
  font-style: italic;
}
input:focus {
  background-color: #000000;
}
input {
  background-color: #000000;
  border: 0px;
  border-radius: 13px;
}
.item-name {
  color: white;
  font-weight: 500;
}
.input-range {
  background-color: white;
  width: 75px;
  border-radius: 15px;
  text-align: center;
}
.input-range:focus {
  background-color: white;
  outline: none;
  text-align: center;
}
.input-range::placeholder {
  text-align: center;
  font-style: normal;
  font-size: 13px;
}
select:focus {
  outline: none;
}
.btn-deselected {
  background-color: #101010;
  border: 1px solid white;
  font-weight: 400;
  color: white;
}
.btn-deselected:hover {
  background-color: white;
  color: black;
}

.hover-pointer:hover{
    cursor: pointer;
}
.rubik{
  font-family: Rubik;
}



.search-bar{
  background-color: #101010;
  border: 0px;
  border-radius: 10px;
}

.search-bar:focus{
  background-color: #101010;
}

.simple-dropdown-container{
  position: absolute;
  width: 120%;
  background: #fff;
  padding: 0.5rem 0;
  margin: 10px 0 0 0px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  z-index: 1000;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1E2021;

  border: 1px solid #E8E8E8;
  box-shadow: 0px 0px 10px rgba(30, 32, 33, 0.15);
  border-radius: 4px;
}

.massedit-dropdown-container{
  margin-top: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1E2021;

  border: 1px solid #E8E8E8;
  box-shadow: 0px 0px 10px rgba(30, 32, 33, 0.15);
  border-radius: 4px;
}

.simple-dropdown-item{
  background-color: white;
  text-decoration: none;
  line-height: 32px;
  padding: 0px 10px 0px 10px;
  user-select: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1E2021;

  &:hover:not(.default-option) {
      color: #ED6B33;
  }

  &.disabled {
      color: #9a9b9b;
  }

  &.selected {
      color: #ED6B33;


      &:hover {
      color: #ED6B33;
      }
  }

  &.disabled {
      cursor: not-allowed;

      &:hover {
      background-color: #fff;
      }
  }
}

.massedit-dropdown-item{
  background-color: white;
  text-decoration: none;
  line-height: 32px;
  padding: 0px 10px 0px 10px;
  user-select: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1E2021;

  &:hover:not(.default-option) {
      color: #ED6B33;
  }

  &.disabled {
      color: #9a9b9b;
  }

  &.selected {
      color: #ED6B33;


      &:hover {
      color: #ED6B33;
      }
  }

  &.disabled {
      cursor: not-allowed;

      &:hover {
      background-color: #fff;
      }
  }
}

.disable-text-select{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Tooltip container */
.item-tooltip-container {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  cursor: pointer;
}

/* Tooltip text */
.item-tooltip-container .item-tooltip {
  visibility: hidden;
  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff; */
  /* text-align: center; */
  /* padding: 5px 0; */
  /* border-radius: 6px; */
 
  /* Position the tooltip text - see examples below! */

  top: 0%;
  left: 60px; /* To the left of the tooltip */
  position: absolute;
  z-index: 1;
}

.item-tooltip-container:hover{
  border-bottom: 1px solid white; 

}
/* Show the tooltip text when you mouse over the tooltip container */
.item-tooltip-container:hover .item-tooltip {
  visibility: visible;
}
</style>